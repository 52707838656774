import { createContext, useContext, useReducer } from "react";
import { useToast } from "./toastContext";
import { applyCouponAPI } from "../api/dealApis";

const CheckoutContext = createContext();

const initialState = {
  step: 2, // 1: Cart, 2: Address, 3: Payment, 4: Review
  billingItems: [],
  billingAddress: null,
  paymentMethod: null,
  orderStatus: "idle", // 'idle', 'processing', 'success', 'error'
  orderId: null,
  subtotal: 0,
  discount: 0,
  tax: 0,
  shippingCost: 0,
  total: 0,
  walletBalance: 0,
  coupon: null,
  couponDiscount: 0,
};

const calculatePrices = (state) => {
  let subtotal = state.billingItems.reduce((sum, item) => {
    if (item.productId) {
      return sum + item.productId.unitPrice * item.itemQuantity;
    }
    return sum;
  }, 0);

  let discount =
    subtotal -
    state.billingItems.reduce((sum, item) => {
      if (item.productId) {
        return sum + item.productId.sellingPrice * item.itemQuantity;
      }
      return sum;
    }, 0);

  let tax = state.billingItems.reduce((sum, item) => {
    if (item.productId) {
      return (
        sum +
        ((item.productId.sellingPrice / (1 + item.productId.taxPercent / 100)) *
          item.itemQuantity *
          item.productId.taxPercent) /
          100
      );
    }
    return sum;
  }, 0);

  // Calculate total before wallet balance, coupon discount, and shipping cost
  let total = subtotal - discount;

  // Calculate the shipping cost based on the total
  let shippingCost = 0;
  if (total <= 499) {
    shippingCost = Math.max(
      ...state.billingItems
        .map((item) => (item.productId ? item.productId.shippingCost : 0))
        .filter((cost) => cost !== undefined),
      60
    );
  }

  // Add shipping cost to the total
  total += shippingCost;

  // Apply wallet balance
  let walletBalance = Math.min(state.walletBalance || 0, total);
  total -= walletBalance;

  // Apply coupon discount
  let couponDiscount = state.couponDiscount || 0;
  if (state.coupon) {
    couponDiscount = Math.min(couponDiscount, total);
    total -= couponDiscount;
  }

  // Ensure total is non-negative
  total = Math.max(total, 0);

  return {
    subtotal,
    discount,
    tax,
    shippingCost,
    total,
    walletBalance,
    couponDiscount,
  };
};

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case "NEXT_STEP":
      return { ...state, step: state.step + 1 };
    case "PREVIOUS_STEP":
      return { ...state, step: state.step - 1 };
    case "SET_STEP":
      return { ...state, step: action.payload };
    case "SET_BILLING_ITEMS":
      return {
        ...state,
        billingItems: action.payload,
        ...calculatePrices({ ...state, billingItems: action.payload }),
      };
    case "SET_BILLING_ADDRESS":
      return { ...state, billingAddress: action.payload };
    case "SELECT_PAYMENT_METHOD":
      return { ...state, paymentMethod: action.payload };
    case "SET_ORDER_STATUS":
      return { ...state, orderStatus: action.payload };
    case "SET_ORDER_ID":
      return { ...state, orderId: action.payload };
    case "SET_WALLET_BALANCE":
      return {
        ...state,
        walletBalance: action.payload,
        ...calculatePrices({ ...state, walletBalance: action.payload }),
      };
    case "APPLY_COUPON":
      return {
        ...state,
        coupon: action.payload.coupon,
        couponDiscount: action.payload.couponDiscount,
        ...calculatePrices({
          ...state,
          coupon: action.payload.coupon,
          couponDiscount: action.payload.couponDiscount,
        }),
      };
    case "REMOVE_COUPON":
      return {
        ...state,
        coupon: null,
        couponDiscount: 0,
        ...calculatePrices({ ...state, coupon: null, couponDiscount: 0 }),
      };
    case "CALCULATE_PRICES":
      return {
        ...state,
        ...calculatePrices(state),
      };
    case "RESET_ORDER":
      return initialState;
    default:
      return state;
  }
};

export const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState);
  const toast = useToast();

  const setBillingItems = (items) => {
    dispatch({ type: "SET_BILLING_ITEMS", payload: items });
  };

  const setBillingAddress = (address) => {
    dispatch({ type: "SET_BILLING_ADDRESS", payload: address });
  };

  const setPaymentMethod = (method) => {
    dispatch({ type: "SELECT_PAYMENT_METHOD", payload: method });
  };

  const applyWalletBalance = (balance) => {
    const deduction = Math.min(balance, state.total);
    dispatch({ type: "SET_WALLET_BALANCE", payload: deduction });
  };

  const applyCoupon = async (couponCode) => {
    try {
      const response = await applyCouponAPI(couponCode, state.total);

      if (response?.status) {
        const { discountAmount } = response.data;
        dispatch({
          type: "APPLY_COUPON",
          payload: {
            coupon: couponCode,
            couponDiscount: Math.round(discountAmount),
          },
        });
        toast.success(`Coupon applied! You saved ${discountAmount}`);
      }
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("no server response");
      } else if (error.response) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error("Server issues!!!");
      }
    }
  };

  const removeCoupon = () => {
    dispatch({ type: "REMOVE_COUPON" });
    toast.info("Coupon removed.");
  };

  const canProceedToNextStep = () => {
    switch (state.step) {
      case 1:
        return state.billingItems.length > 0;
      case 2:
        return state.billingAddress !== null;
      case 3:
        return state.paymentMethod !== null;
      default:
        return true;
    }
  };

  const validationMessages = {
    1: "Please add some items into cart before proceeding.",
    2: "Please add a billing address to continue.",
    3: "Please select a payment method to proceed.",
    4: "Please review and confirm your order before submitting.",
  };

  const toStep = (step) => {
    if (
      step <= state.step ||
      (state.billingItems.length > 0 &&
        state.billingAddress !== null &&
        state.paymentMethod !== null)
    ) {
      dispatch({ type: "SET_STEP", payload: step });
    }
  };

  const nextStep = () => {
    if (canProceedToNextStep()) {
      dispatch({ type: "NEXT_STEP" });
    } else {
      toast.warning(validationMessages[state.step]);
    }
  };

  const previousStep = () => {
    if (state.step > 1) {
      dispatch({ type: "PREVIOUS_STEP" });
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        state,
        dispatch,
        setBillingItems,
        setBillingAddress,
        setPaymentMethod,
        applyWalletBalance,
        applyCoupon,
        removeCoupon,
        canProceedToNextStep,
        previousStep,
        nextStep,
        toStep,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => useContext(CheckoutContext);
