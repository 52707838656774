import "./index.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { StrictMode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "./contexts/modalContext";
import { UserProvider } from "./contexts/userContext";
import { ToastProvider } from "./contexts/toastContext";
import { CartProvider } from "./contexts/cartContext";
import { WishlistProvider } from "./contexts/wishlistContext";
import { AddressProvider } from "./contexts/addressContext";
import { CheckoutProvider } from "./contexts/checkoutContext";
import { WalletProvider } from "./contexts/walletContext";
import { AuthProvider } from "./contexts/authContext";
import { RecentlyViewedProvider } from "./contexts/RecentlyViewedContext";
import { SearchHistoryProvider } from "./contexts/searchHistoryContext";
import { SidebarProvider } from "./contexts/sidebarContext";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastProvider>
          <AuthProvider>
            <UserProvider>
              <SearchHistoryProvider>
                <RecentlyViewedProvider>
                  <CheckoutProvider>
                    <CartProvider>
                      <WishlistProvider>
                        <AddressProvider>
                          <ModalProvider>
                            <SidebarProvider>
                              <WalletProvider>
                                <App />
                              </WalletProvider>
                            </SidebarProvider>
                          </ModalProvider>
                        </AddressProvider>
                      </WishlistProvider>
                    </CartProvider>
                  </CheckoutProvider>
                </RecentlyViewedProvider>
              </SearchHistoryProvider>
            </UserProvider>
          </AuthProvider>
        </ToastProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);

reportWebVitals();
