import { getCookie } from "../utils/cookie";
import axiosInstance from "./axiosInstance";

const registerUser = (userData) => {
  return axiosInstance.post("/register", userData);
};

const loginUser = (loginData) => {
  return axiosInstance.post("/login", loginData);
};

const sendOtp = (email) => {
  return axiosInstance.post("/send_mail_Varification", { email });
};

const verifyUser = (credentials) => {
  return axiosInstance.post("/otp_varification", credentials);
};

const sendForgetPasswordOtp = (email) => {
  return axiosInstance.post("/sendMailForUpdatePassword", { email });
};

const resetPassword = (credentials) => {
  return axiosInstance.post("/updatepassword", credentials);
};

const getUserDetails = (token) => {
  return axiosInstance.get("/userDetails", {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

const updateUserDetails = (userData) => {
  const token = getCookie("accessToken");
  return axiosInstance.post("/updateUserDetails", userData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: "Bearer " + token,
    },
  });
};

const deleteUserAccount = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/deleteUserAccountFromUser", {
    headers: { authorization: `Bearer ${token}` },
  });
};

const logOutUser = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/logout", {
    headers: { authorization: `Bearer ${token}` },
  });
};

const refreshTokenAPI = (refreshToken) => {
  return axiosInstance.post("/verifyRefreshToken", { refreshToken });
};

const verifyToken = (token) => {
  return axiosInstance.post("/validAccessToken", { token });
};

const generateReferralCode = async () => {
  const response = await axiosInstance.get("/shareReferralCode");
  return response.data?.referralLink;
};

export {
  registerUser,
  loginUser,
  sendOtp,
  verifyUser,
  sendForgetPasswordOtp,
  resetPassword,
  getUserDetails,
  updateUserDetails,
  deleteUserAccount,
  logOutUser,
  refreshTokenAPI,
  verifyToken,
  generateReferralCode,
};
