import { lazy, memo, Suspense } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useUser } from "../contexts/userContext";
import Loader from "./Loader";
import useMediaQuery from "../hooks/useMediaQuery";
import useScrollToTop from "../hooks/useScrollToTop";

// Lazy Imports
const PrimaryNav = lazy(() => import("../layouts/PrimaryNav"));
const CategoryNav = lazy(() => import("../layouts/CategoryNav"));
const FallbackNav = lazy(() => import("../layouts/FallbackNav"));
const Footer = lazy(() => import("../layouts/Footer"));
const Home = lazy(() => import("../pages/Home"));
const About = lazy(() => import("../pages/About"));
const Account = lazy(() => import("../pages/Account"));
const Profile = lazy(() => import("../pages/Profile"));
const Wallet = lazy(() => import("../pages/Wallet"));
const Orders = lazy(() => import("../pages/Orders"));
const Auth = lazy(() => import("../pages/Auth"));
const GoogleCallback = lazy(() => import("../pages/Auth/GoogleCallback"));
const Wishlist = lazy(() => import("../pages/Wishlist"));
const Search = lazy(() => import("../pages/Search"));
const Products = lazy(() => import("../pages/Products"));
const Product = lazy(() => import("../pages/Product"));
const Checkout = lazy(() => import("../pages/Checkout"));
const GiftCard = lazy(() => import("../pages/GiftCard"));
const Settings = lazy(() => import("../pages/settings"));
const Order = lazy(() => import("../pages/Order"));
const Address = lazy(() => import("../pages/Address"));
const UtsavHome = lazy(() => import("../pages/Utsav/Home"));
const BecomeUtsav = lazy(() => import("../pages/BecomeUtsav"));
const Category = lazy(() => import("../pages/Category"));
const Brand = lazy(() => import("../pages/Brand"));
const ProductType = lazy(() => import("../pages/ProductType"));
const Subcategory = lazy(() => import("../pages/Subcategory"));
const Deal = lazy(() => import("../pages/Deal"));
const CreateCard = lazy(() => import("../pages/GiftCard/CreateCard"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("../pages/TermsAndConditions"));
const ReturnRefundPolicy = lazy(() => import("../pages/ReturnRefundPolicy"));
const ShippingPolicy = lazy(() => import("../pages/ShippingPolicy"));
const ScrollToTop = lazy(() => import("./ScrollToTop"));

const AnimatedRoutes = () => {
  useScrollToTop();
  const { user } = useUser();
  const location = useLocation();
  const maxLg = useMediaQuery("(min-width: 1024px)");

  const requireAuth = () => {
    return user ? (
      <Outlet />
    ) : (
      <Navigate to="/auth" state={{ from: location }} replace />
    );
  };

  const notRequireAuth = () => {
    return user ? (
      <Navigate
        to={location.state?.from || "/"}
        state={{ from: location }}
        replace
      />
    ) : (
      <Outlet />
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <Routes location={location}>
        <Route
          path="/"
          element={
            <>
              <main className="min-h-screen">
                {/* <TopNav /> */}
                <PrimaryNav />
                <CategoryNav />
                <FallbackNav />
                {/* <TextNav /> */}
                <ScrollToTop />
                <Outlet key={location.pathname} />
              </main>
              <Footer />
            </>
          }
        >
          {/* All Public Routes */}
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="category/:categoryId" element={<Category />} />
          <Route path="subcategory/:subcategoryId" element={<Subcategory />} />
          <Route path="product-type/:productTypeId" element={<ProductType />} />
          <Route path="brand/:brandId" element={<Brand />} />
          <Route
            path="flash-deal/:dealId"
            element={<Deal dealName="flash" />}
          />
          <Route
            path="featured-deal/:dealId"
            element={<Deal dealName="featured" />}
          />
          <Route path="products" element={<Products />} />
          <Route path="product/:productId" element={<Product />} />
          <Route path="search" element={<Search />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="return-refund-policy" element={<ReturnRefundPolicy />} />
          <Route path="shipping-delivery-policy" element={<ShippingPolicy />} />
          {/* Auth Route */}
          <Route element={notRequireAuth()}>
            <Route path="auth" element={<Auth />} />
            <Route path="auth/callback" element={<GoogleCallback />} />
          </Route>
          {/* Protected Routes */}
          <Route element={requireAuth()}>
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="order/:orderId" element={<Order />} />
            <Route path="account" element={maxLg && <Account />}>
              <Route
                index
                element={
                  maxLg ? (
                    <Navigate to="profile" state={{ from: location }} replace />
                  ) : (
                    <Account />
                  )
                }
              />
              <Route path="profile" element={<Profile />} />
              <Route path="orders" element={<Orders />} />
              <Route path="gift-card">
                <Route index element={<GiftCard />} />
                <Route path="create" element={<CreateCard />} />
              </Route>
              <Route path="become-utsav" element={<BecomeUtsav />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="address" element={<Address />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>
        </Route>
        {/* Checkout Route */}
        <Route element={requireAuth()}>
          <Route path="/checkout" element={<Checkout />} />
        </Route>
        {/* Utsav Route */}
        <Route
          path="utsav"
          element={
            <>
              <main className="min-h-screen">
                <PrimaryNav />
                <ScrollToTop />
                <Outlet key={location.pathname} />
              </main>
              <Footer />
            </>
          }
        >
          <Route index element={<UtsavHome />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default memo(AnimatedRoutes);
