import { createContext, useContext, useState } from "react";
import { AnimatePresence } from "framer-motion";
import Sidebar from "../components/Sidebar";

const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const [content, setContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openSidebar = (content) => {
    setContent(content);
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeSidebar = () => {
    setContent(null);
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  return (
    <SidebarContext.Provider
      value={{ openSidebar, closeSidebar, isOpen, content }}
    >
      {children}
      <div className="sidebar-area">
        <AnimatePresence initial={false}>
          {isOpen && <Sidebar />}
        </AnimatePresence>
      </div>
    </SidebarContext.Provider>
  );
}

export function useSidebar() {
  return useContext(SidebarContext);
}
